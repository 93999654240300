import React from 'react'
import GlitchTextAnimated from './GlitchTextAnimated'
import { Link } from 'react-router-dom'

export default function OnboardingWaiting() {
    return (
        <div className='full-screen-onboarding'>
            <div className='onboarding-waiting'>
                <GlitchTextAnimated text='lynkd' />
                <p className='onboarding-first'>We have received your details and you will be notified on your registered email address when your account is approved.</p>
                <p className='onboarding-second'>You will receive a reply in 2-4 working days about your account status.</p>
                <p className='onboarding-third'>Thank you for taking the first step to success with lynkd.</p>
                {/* visit landing page */}
                <div className='onboarding-visit-landing'>
                    <Link to="https://lynkd.app/brands" style={{ color: 'black', textDecoration: 'none', width: '100%', display: 'block', textAlign: 'center' }}>
                        Checkout our brands page
                    </Link>
                </div>
            </div>
        </div>
    )
}
