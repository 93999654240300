import React, { useState } from 'react'
import { BiPlus } from 'react-icons/bi';

function AddProduct() {
    const [allowAffiliations, setAllowAffiliations] = useState(true);

    const toggleAffiliations = () => {
        setAllowAffiliations(!allowAffiliations);
    };

    const [mainImage, setMainImage] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);

    const handleMainImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setMainImage(imageUrl);
        }
    };

    const handleGalleryImageChange = (index, e) => {
        const file = e.target.files[0];
        if (file) {
            const newGalleryImages = [...galleryImages];
            newGalleryImages[index] = URL.createObjectURL(file);
            setGalleryImages(newGalleryImages);
        }
    };

    return (
        <div className='add-product'>
            <div className="add-product-header">
                <div>
                    <h1>Add Product</h1>
                    <p style={{ marginTop: -20 }}>Add product for your customers</p>
                </div>
                <div style={{ display: 'flex' }}>
                    <button className="bulk-update-button">Bulk Upload CSV</button>
                    <button className="save-product-button">Save Product</button>
                </div>
            </div>
            <div className="product-form">
                <div className="form-section basic-info">
                    <h3>Basic Information</h3>
                    <label htmlFor="product-name">Product Name</label>
                    <div className='product-input'>
                        <input type="text" placeholder="Product Name" />
                    </div>
                    <label htmlFor="product-description">Product Description</label>
                    <div className='product-textarea'>
                        <textarea placeholder="Product Description"></textarea>
                    </div>
                    <label htmlFor="product-tags">Product Tags</label>
                    <div className='product-input'>
                        <input type="text" placeholder="Product Tags" />
                    </div>
                    <label htmlFor="product-tags">Product SKU</label>
                    <div className='product-input'>
                        <input type="text" placeholder="Product SKU" />
                    </div>
                </div>


                <div className="form-section affiliations">
                    <h3>Affiliations</h3>
                    <div style={{ display: 'flex', gap: 10, margin: "10px 0px" }}>
                        <span>Allow Affiliations</span>
                        <label className="switch">
                            <input type="checkbox" checked={allowAffiliations} onChange={toggleAffiliations} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    {allowAffiliations && <>
                        <label htmlFor="affiliate-commission">Affilate Requirement</label>
                        <div className='product-input'>
                            <input type="text" placeholder="Afflilate Commission %" disabled={!allowAffiliations} />
                        </div>
                        <label htmlFor="affiliate-requirement">Affilate Requirement</label>
                        <div className='product-input'>
                            <input type="text" placeholder="Creator Minimum Follower Requirement" disabled={!allowAffiliations} />
                        </div>
                        <label htmlFor="affiliation-link">Affiliation Link</label>
                        <div className='product-input'>
                            <input type="text" placeholder="Affiliation Link" disabled={!allowAffiliations} />
                        </div>
                    </>
                    }
                </div>
                <div className="form-section product-images">
                    <h3>Product Images</h3>
                    <div className="image-upload">
                        <div className="main-image">
                            <span style={{ width: '100%' }}>Main Image</span>
                            <div className="image-placeholder-main">
                                {mainImage ? (
                                    <img src={mainImage} alt="Main" className="uploaded-image" />
                                ) : (
                                    <>
                                        <label htmlFor="main-image-upload" className="image-upload-label" style={{ width: '100%', height: '100%' }}>
                                            <BiPlus size={40} />
                                        </label>
                                        <input
                                            type="file"
                                            id="main-image-upload"
                                            style={{ display: 'none' }}
                                            onChange={handleMainImageChange}
                                            accept="image/*"
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="gallery-images">
                            <span style={{ width: '100%' }}>Product Gallery Images</span>
                            {[...Array()].map((_, i) => (
                                <div key={i} className="image-placeholder">
                                    {galleryImages[i] ? (
                                        <img src={galleryImages[i]} alt={`Gallery ${i}`} className="uploaded-image" />
                                    ) : (
                                        <>
                                            <label htmlFor={`gallery-image-upload-${i}`} className="image-upload-label" style={{ width: '100%', height: '100%' }}>
                                                <BiPlus size={20} />
                                            </label>
                                            <input
                                                type="file"
                                                id={`gallery-image-upload-${i}`}
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleGalleryImageChange(i, e)}
                                                accept="image/*"
                                            />
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <br />
                    <label htmlFor="product-description">Image URLs</label>
                    <div className='product-textarea image-urls-area'>
                        <textarea placeholder="Image URLs (seperated by comma)"></textarea>
                    </div>
                </div>
                <div className="form-section price-section">
                    <h3>Price</h3>
                    <label htmlFor="regular-price">Regular Price</label>
                    <div className='product-input'>
                        <input type="text" placeholder="Regular Price" />
                    </div>
                    <label htmlFor="sale-price">Discount Price</label>
                    <div className='product-input'>
                        <input type="text" placeholder="Discount Price" />
                    </div>
                    <label htmlFor="affiliate-price">Affiliate Price</label>
                    <div className='product-input'>
                        <input type="text" placeholder="Affiliate Price" />
                    </div>
                    <label htmlFor="cost-price">Discount Expiry Date</label>
                    <div className='product-input'>
                        <input type="date" placeholder="Discount Expiry Date" />
                    </div>
                </div>

            </div>

            <div className="form-section">
                <h3>Category</h3>
                <div className='category-section'>
                    <div>
                        <label htmlFor="product-category">Product Category</label>
                        <br />
                        <select className='add-category-select'>
                            <option>Select Category</option>
                            <option>Category 1</option>
                            <option>Category 2</option>
                            <option>Category 3</option>
                            <option>Category 4</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="add-category">Add Category</label>
                        <div className="add-category">
                            <br />
                            <div className='product-input'>
                                <input type="text" placeholder="Enter Category" />
                            </div>
                            <button>Add Category</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddProduct