import React from "react";
import Header from "../Header";
import StatsCard from "./StatsCard";
import { BiBox, BiDollarCircle, BiSolidMessageDots } from "react-icons/bi";
import { BsPersonBadge } from "react-icons/bs";
import { FaPeopleGroup } from "react-icons/fa6";
import { AiOutlineProduct } from "react-icons/ai";
import PopularProducts from "./PopularProducts";
import PopularCreators from "./PopularCreators";
import LoyalCustomers from "./LoyalCustomers";

const Dashboard = () => {
    return (
        <div className="dashboard">
            <Header />
            <div className="dashboard-summary">
                <StatsCard title="Orders" value="750" icon={<BiBox size={40} />} />
                <StatsCard title="Revenue" value="1,234$" icon={<BiDollarCircle size={40} />} />
                <StatsCard title="Affiliated Creators" value="800" icon={<BsPersonBadge size={40} />} />
                <StatsCard title="Customers" value="20" icon={<FaPeopleGroup size={40} />} />
                <StatsCard title="Products" value="1523" icon={<AiOutlineProduct size={40} />} />
                <StatsCard title="Unread Messages" value="20" icon={<BiSolidMessageDots size={40} />} />
            </div>
            {/* <div className="dashboard-details">
                <PackageInfo />
                <Map />
            </div>
            <div className="dashboard-stats">
                <SpeedStatistics />
                <Transportations />
            </div> */}
            <div className="dashboard-details">
                <PopularProducts />
            </div>
            <div className="dashboard-details">
                <PopularCreators />
            </div>
            <div className="dashboard-details">
                <LoyalCustomers />
            </div>
        </div>
    );
};

export default Dashboard;