import React, { useEffect, useState } from 'react'
import { BiChevronLeft, BiChevronRight, BiFilter, BiSearch } from 'react-icons/bi'
import { Link } from 'react-router-dom'

const orders = [
    {
        "name": "Sophia Rivera",
        "profilePhoto": "https://randomuser.me/api/portraits/women/21.jpg",
        "category": "Fitness",
        "followers": 250000,
        "engagementRate": "4.5%",
        "brandsAffiliated": 12,
        "averageLikes": 11250,
        "averageComments": 430,
        "platforms": ["Instagram", "YouTube"],
        "contentFormat": ["Short Videos", "Instagram Stories", "Product Reviews"],
        "topAffiliatedBrands": ["Nike", "Under Armour", "Fitbit"]
    },
    {
        "name": "Ethan Turner",
        "profilePhoto": "https://randomuser.me/api/portraits/men/12.jpg",
        "category": "Technology",
        "followers": 780000,
        "engagementRate": "3.8%",
        "brandsAffiliated": 20,
        "averageLikes": 29500,
        "averageComments": 870,
        "platforms": ["YouTube", "Twitter"],
        "contentFormat": ["Tech Reviews", "Live Streams", "Unboxings"],
        "topAffiliatedBrands": ["Apple", "Samsung", "Sony"]
    },
    {
        "name": "Lily O'Connor",
        "profilePhoto": "https://randomuser.me/api/portraits/women/18.jpg",
        "category": "Fashion",
        "followers": 350000,
        "engagementRate": "5.2%",
        "brandsAffiliated": 15,
        "averageLikes": 18200,
        "averageComments": 600,
        "platforms": ["Instagram", "TikTok"],
        "contentFormat": ["Outfit Hauls", "Fashion Tips", "Styling Tutorials"],
        "topAffiliatedBrands": ["Gucci", "Zara", "H&M"]
    },
    {
        "name": "Olivia Brown",
        "profilePhoto": "https://randomuser.me/api/portraits/women/44.jpg",
        "category": "Beauty",
        "followers": 980000,
        "engagementRate": "6.1%",
        "brandsAffiliated": 25,
        "averageLikes": 59800,
        "averageComments": 2200,
        "platforms": ["Instagram", "YouTube"],
        "contentFormat": ["Makeup Tutorials", "Skincare Routines", "Product Reviews"],
        "topAffiliatedBrands": ["Sephora", "Fenty Beauty", "L'Oreal"]
    },
    {
        "name": "James Lewis",
        "profilePhoto": "https://randomuser.me/api/portraits/men/33.jpg",
        "category": "Travel",
        "followers": 410000,
        "engagementRate": "7.4%",
        "brandsAffiliated": 18,
        "averageLikes": 30200,
        "averageComments": 1300,
        "platforms": ["Instagram", "YouTube", "Blog"],
        "contentFormat": ["Travel Vlogs", "Destination Reviews", "Travel Guides"],
        "topAffiliatedBrands": ["Airbnb", "Booking.com", "Expedia"]
    },
    {
        "name": "Mia Martinez",
        "profilePhoto": "https://randomuser.me/api/portraits/women/10.jpg",
        "category": "Health & Wellness",
        "followers": 650000,
        "engagementRate": "5.9%",
        "brandsAffiliated": 14,
        "averageLikes": 38300,
        "averageComments": 1150,
        "platforms": ["Instagram", "TikTok"],
        "contentFormat": ["Meditation Tips", "Healthy Recipes", "Workout Routines"],
        "topAffiliatedBrands": ["Nike", "Adidas", "Lululemon"]
    },
    {
        "name": "Liam Johnson",
        "profilePhoto": "https://randomuser.me/api/portraits/men/25.jpg",
        "category": "Gaming",
        "followers": 1200000,
        "engagementRate": "8.2%",
        "brandsAffiliated": 30,
        "averageLikes": 98400,
        "averageComments": 3200,
        "platforms": ["Twitch", "YouTube", "Twitter"],
        "contentFormat": ["Live Streams", "Game Walkthroughs", "Product Unboxings"],
        "topAffiliatedBrands": ["Razer", "Corsair", "Xbox"]
    },
    {
        "name": "Emily Clark",
        "profilePhoto": "https://randomuser.me/api/portraits/women/47.jpg",
        "category": "Parenting",
        "followers": 350000,
        "engagementRate": "5.0%",
        "brandsAffiliated": 10,
        "averageLikes": 17500,
        "averageComments": 400,
        "platforms": ["Instagram", "YouTube"],
        "contentFormat": ["Parenting Tips", "Family Vlogs", "Product Recommendations"],
        "topAffiliatedBrands": ["Pampers", "Gerber", "Huggies"]
    },
    {
        "name": "Noah Harris",
        "profilePhoto": "https://randomuser.me/api/portraits/men/16.jpg",
        "category": "Photography",
        "followers": 850000,
        "engagementRate": "7.0%",
        "brandsAffiliated": 22,
        "averageLikes": 59500,
        "averageComments": 1800,
        "platforms": ["Instagram", "YouTube"],
        "contentFormat": ["Photography Tutorials", "Camera Reviews", "Behind-the-Scenes Shoots"],
        "topAffiliatedBrands": ["Canon", "Nikon", "Sony"]
    },
    {
        "name": "Ava Roberts",
        "profilePhoto": "https://randomuser.me/api/portraits/women/37.jpg",
        "category": "Food & Cooking",
        "followers": 490000,
        "engagementRate": "6.3%",
        "brandsAffiliated": 15,
        "averageLikes": 30870,
        "averageComments": 1400,
        "platforms": ["Instagram", "YouTube", "TikTok"],
        "contentFormat": ["Recipe Videos", "Cookbook Reviews", "Food Photography"],
        "topAffiliatedBrands": ["Tasty", "KitchenAid", "Blue Apron"]
    },
    {
        "name": "Lucas Walker",
        "profilePhoto": "https://randomuser.me/api/portraits/men/40.jpg",
        "category": "Automotive",
        "followers": 290000,
        "engagementRate": "5.8%",
        "brandsAffiliated": 17,
        "averageLikes": 16820,
        "averageComments": 800,
        "platforms": ["Instagram", "YouTube", "Blog"],
        "contentFormat": ["Car Reviews", "Driving Experiences", "Tech Installations"],
        "topAffiliatedBrands": ["Tesla", "BMW", "Ford"]
    },
    {
        "name": "Isabella Green",
        "profilePhoto": "https://randomuser.me/api/portraits/women/30.jpg",
        "category": "Lifestyle",
        "followers": 730000,
        "engagementRate": "4.9%",
        "brandsAffiliated": 20,
        "averageLikes": 35770,
        "averageComments": 1020,
        "platforms": ["Instagram", "TikTok"],
        "contentFormat": ["Home Decor", "Fashion Tips", "Daily Vlogs"],
        "topAffiliatedBrands": ["IKEA", "H&M", "Target"]
    },
    {
        "name": "Alexander King",
        "profilePhoto": "https://randomuser.me/api/portraits/men/15.jpg",
        "category": "Sports",
        "followers": 950000,
        "engagementRate": "7.2%",
        "brandsAffiliated": 28,
        "averageLikes": 68400,
        "averageComments": 2900,
        "platforms": ["Instagram", "YouTube", "Twitter"],
        "contentFormat": ["Training Videos", "Match Highlights", "Product Endorsements"],
        "topAffiliatedBrands": ["Adidas", "Gatorade", "Under Armour"]
    }
];



export default function BrandAmbassadors() {
    const [currentPage, setCurrentPage] = useState(1);
    const [ordersPerPage, setOrdersPerPage] = useState(10); // Default is 3 orders per page
    const [selectedStatus, setSelectedStatus] = useState('');  // Filter state
    // Calculate the indexes of orders for the current page
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const [filteredOrders, setFilteredOrders] = useState(orders.slice(indexOfFirstOrder, indexOfLastOrder));
    const [activeStatuses, setActiveStatuses] = useState(
        filteredOrders.reduce((acc, campaign) => {
            acc[campaign.campaignId] = campaign.status === 'Active';
            return acc;
        }, {})
    );

    const toggleStatus = (campaignId) => {
        setActiveStatuses((prevStatuses) => ({
            ...prevStatuses,
            [campaignId]: !prevStatuses[campaignId]
        }));
    };
    const nextPage = () => {
        if (currentPage < Math.ceil(orders.length / ordersPerPage)) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const [search, setSearch] = useState('');

    useEffect(() => {
        const filteredOrdersTemp = orders.filter(order =>
            order.name.toLowerCase().includes(search.toLowerCase()) ||
            order.category.toLowerCase().includes(search.toLowerCase()));
        const slicedFilters = filteredOrdersTemp.slice(indexOfFirstOrder, indexOfLastOrder);
        setFilteredOrders(slicedFilters);
    }, [search, ordersPerPage, currentPage]);

    return (
        <div className="shop">
            <div className='shop-header'>
                <div className="shop-container">
                    <h1 style={{ width: 520 }}>Affiliated Brand Ambassadors</h1>

                    <div className="shop-search">
                        <BiSearch color='blue' size={30} />
                        <input type="text" placeholder="Search Creators by Name or Category"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                        />
                    </div>
                </div>
                <div className="shop-container">
                    <div className="products-per-page">
                        <label htmlFor="ordersPerPage" style={{ width: 150 }}>Creators per page: </label>
                        <select
                            id="ordersPerPage"
                            onChange={(e) => setOrdersPerPage(Number(e.target.value))}
                            value={ordersPerPage}
                        >
                            <option value={3}>3</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                        </select>
                    </div>
                    {/* <button className="filter-button">
                        <BiFilter color='blue' size={30} />
                        Filter
                    </button> */}
                    <Link to={"/brand-ambassadors/requests"}
                        style={{ color: 'white', textDecoration: 'none' }}
                        className="add-product-button"
                    >
                        View Requests
                    </Link>
                </div>
            </div>
            <div className="table-container">
                <table className="product-table ad-table" style={{ textAlign: 'center' }}>
                    <thead>
                        <tr>
                            <th>Photo</th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Followers</th>
                            <th>Engagement Rate</th>
                            <th>Average Likes</th>
                            <th>Average Comments</th>
                            <th>Brands Affiliated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredOrders.map((creator, index) => (
                                <tr key={creator.name} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f8f8f8' }}>
                                    {/* Profile Photo */}
                                    <td>
                                        <div
                                            style={{
                                                width: 60
                                            }}>
                                            <img
                                                src={creator.profilePhoto}
                                                alt={creator.name}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    borderRadius: '50%'
                                                }}
                                            />
                                        </div>
                                    </td>

                                    {/* Name */}
                                    <td>
                                        <div
                                            style={{
                                                width: 110
                                            }}>{creator.name}</div>
                                    </td>

                                    {/* Category */}
                                    <td>
                                        <div
                                            style={{
                                                width: 110
                                            }}>{creator.category}</div>
                                    </td>

                                    {/* Followers */}
                                    <td>
                                        <div
                                            style={{
                                                width: 80
                                            }}>{creator.followers.toLocaleString()}</div>
                                    </td>

                                    {/* Engagement Rate */}
                                    <td>
                                        <div
                                            style={{
                                                width: 80
                                            }}>{creator.engagementRate}</div>
                                    </td>

                                    {/* Average Likes */}
                                    <td>
                                        <div
                                            style={{
                                                width: 100
                                            }}>{creator.averageLikes.toLocaleString()}</div>
                                    </td>

                                    {/* Average Comments */}
                                    <td>
                                        <div
                                            style={{
                                                width: 150
                                            }}>{creator.averageComments.toLocaleString()}</div>
                                    </td>

                                    {/* Brands Affiliated */}
                                    <td>
                                        <div
                                            style={{
                                                width: 150
                                            }}>{creator.brandsAffiliated}</div>
                                    </td>

                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>


            <div className="pagination">
                <span>
                    Showing {indexOfFirstOrder + 1} - {Math.min(indexOfLastOrder, orders.length)} of {orders.length} Creators
                </span>
                <div className="pagination-controls">
                    <button className="prev-btn" onClick={prevPage} disabled={currentPage === 1}>
                        <BiChevronLeft size={40} color='black' />
                    </button>
                    <span>{currentPage} / {Math.ceil(orders.length / ordersPerPage)}</span>
                    <button className="next-btn" onClick={nextPage} disabled={currentPage === Math.ceil(orders.length / ordersPerPage)}>
                        <BiChevronRight size={40} color='black' />
                    </button>
                </div>
            </div>
        </div>
    )
}