import React, { useEffect, useState } from 'react'
import { BiChevronLeft, BiChevronRight, BiFilter, BiSearch } from 'react-icons/bi'
import { Link } from 'react-router-dom'

const orders = [
    {
        "campaignId": "CAMP001",
        "campaignName": "Male weight loss",
        "status": "Active",
        "budget": "€10 Day",
        "reach": 1000,
        "clicks": 20,
        "conversions": 15,
        "costPerClick": "$20",
        "dateRange": "May 07-May 20, 23"
    },
    {
        "campaignId": "CAMP002",
        "campaignName": "Women weight loss",
        "status": "Inactive",
        "budget": "€40 Week",
        "reach": 1000,
        "clicks": 50,
        "conversions": 15,
        "costPerClick": "$50",
        "dateRange": "May 07-May 20, 23"
    },
    {
        "campaignId": "CAMP003",
        "campaignName": "Gain your muscle in 10...",
        "status": "Review",
        "budget": "€100 Month",
        "reach": 1000,
        "clicks": 70,
        "conversions": 15,
        "costPerClick": "$70",
        "dateRange": "May 07-May 20, 23"
    },
    {
        "campaignId": "CAMP004",
        "campaignName": "Divine path for bright life",
        "status": "Scheduled",
        "budget": "€10 Day",
        "reach": 1000,
        "clicks": 90,
        "conversions": 15,
        "costPerClick": "$90",
        "dateRange": "May 07-May 20, 23"
    },
    {
        "campaignId": "CAMP005",
        "campaignName": "Be new flower today",
        "status": "Complete",
        "budget": "€40 Week",
        "reach": 1000,
        "clicks": 50,
        "conversions": 15,
        "costPerClick": "$50",
        "dateRange": "May 07-May 20, 23"
    }
]


export default function AdsHome() {
    const [currentPage, setCurrentPage] = useState(1);
    const [ordersPerPage, setOrdersPerPage] = useState(10); // Default is 3 orders per page
    const [selectedStatus, setSelectedStatus] = useState('');  // Filter state
    // Calculate the indexes of orders for the current page
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const [filteredOrders, setFilteredOrders] = useState(orders.slice(indexOfFirstOrder, indexOfLastOrder));
    const [activeStatuses, setActiveStatuses] = useState(
        filteredOrders.reduce((acc, campaign) => {
            acc[campaign.campaignId] = campaign.status === 'Active';
            return acc;
        }, {})
    );

    const toggleStatus = (campaignId) => {
        setActiveStatuses((prevStatuses) => ({
            ...prevStatuses,
            [campaignId]: !prevStatuses[campaignId]
        }));
    };
    const nextPage = () => {
        if (currentPage < Math.ceil(orders.length / ordersPerPage)) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const filterOrders = () => {
        if (selectedStatus === '') {
            setFilteredOrders(orders); // If no status is selected, show all orders
        } else {
            const filtered = orders.filter(order => order.status === selectedStatus);
            setFilteredOrders(filtered); // Update with filtered orders
        }
    };


    const [search, setSearch] = useState('');

    useEffect(() => {
        filterOrders();
    }, [selectedStatus]);

    useEffect(() => {
        const filteredOrdersTemp = orders.filter(order =>
            order.campaignName.toLowerCase().includes(search.toLowerCase()) ||
            order.campaignId.toLowerCase().includes(search.toLowerCase())
        );
        const slicedFilters = filteredOrdersTemp.slice(indexOfFirstOrder, indexOfLastOrder);
        setFilteredOrders(slicedFilters);
    }, [search, ordersPerPage, currentPage]);

    const getStatusColor = (status) => {
        switch (status) {
            case 'Active':
                return '#28a745';  // Green color for active
            case 'Inactive':
                return '#dc3545';  // Red color for inactive
            case 'Review':
                return '#ffc107';  // Yellow color for review
            case 'Scheduled':
                return '#007bff';  // Blue color for scheduled
            case 'Complete':
                return '#17a2b8';  // Teal color for complete
            default:
                return '#6c757d';  // Gray color for other statuses
        }
    };

    return (
        <div className="shop">
            <div className='shop-header'>
                <div className="shop-container">
                    <h1>Ad Campaigns</h1>
                    <div className="shop-search">
                        <BiSearch color='blue' size={30} />
                        <input type="text" placeholder="Search Campaign by Name or ID"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                        />
                    </div>
                </div>
                <div className="shop-container">
                    <div className="products-per-page">
                        <label htmlFor="ordersPerPage">Campaigns per page: </label>
                        <select
                            id="ordersPerPage"
                            onChange={(e) => setOrdersPerPage(Number(e.target.value))}
                            value={ordersPerPage}
                        >
                            <option value={3}>3</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                        </select>
                    </div>
                    {/* <button className="filter-button">
                        <BiFilter color='blue' size={30} />
                        Filter
                    </button> */}
                    <div className="filter-section">
                        <select
                            id="orderStatusFilter"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <option value="">All Campaigns</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                            <option value="Review">Review</option>
                            <option value="Scheduled">Scheduled</option>
                            <option value="Complete">Complete</option>
                        </select>
                        {/* <button className="filter-button" onClick={filterCampaigns}>
                        <BiFilter color='blue' size={30} />
                        Filter
                    </button> */}
                        <Link to={"/ads-management/create-campaign"}
                            style={{ color: 'white', textDecoration: 'none' }}
                            className="add-product-button"
                        >
                            Create Campaign
                        </Link>
                    </div>




                    <Link to={"/orders/add-order"}
                        style={{ color: 'white', textDecoration: 'none' }}
                        className="add-order-button"
                    >
                        Add Order
                    </Link>
                </div>
            </div>
            <div className="table-container">
                <table className="product-table ad-table">
                    <thead>
                        <tr>
                            <th>Active</th>
                            <th>Campaign ID</th>
                            <th>Campaign Name</th>
                            <th>Status</th>
                            <th>Budget</th>
                            <th>Reach</th>
                            <th>Clicks</th>
                            <th>Conversions</th>
                            <th>Cost Per Click</th>
                            <th>Date Range</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredOrders.map((campaign, index) => (
                                <tr key={campaign.campaignId} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f8f8f8' }}>
                                    <td>
                                        <div
                                            style={{
                                                width: 120
                                            }}>
                                            <div className="toggle-switch">
                                                <input
                                                    type="checkbox"
                                                    id={`statusToggle-${campaign.campaignId}`}
                                                    checked={activeStatuses[campaign.campaignId] || false}
                                                    onChange={() => toggleStatus(campaign.campaignId)}
                                                />
                                                <label htmlFor={`statusToggle-${campaign.campaignId}`}>
                                                    <span className="slider"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </td>


                                    <td>
                                        <div
                                            style={{
                                                width: 110
                                            }}>{campaign.campaignId}</div>
                                    </td>

                                    <td>
                                        <div
                                            style={{
                                                width: 110
                                            }}>{campaign.campaignName}</div>
                                    </td>

                                    <td>
                                        <div className="order-item">
                                            <div
                                                className="order-status"
                                                style={{
                                                    border: `3px solid ${getStatusColor(campaign.status)}`,
                                                    backgroundColor: '#fff',
                                                    padding: '10px',
                                                    textAlign: 'center',
                                                    borderRadius: 15,
                                                    width: 70,
                                                    display: 'inline-block'
                                                }}
                                            >
                                                {campaign.status}
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div
                                            style={{
                                                width: 50
                                            }}>{campaign.budget}</div>
                                    </td>

                                    <td>
                                        <div
                                            style={{
                                                width: 50
                                            }}>{campaign.reach}</div>
                                    </td>

                                    <td>
                                        <div
                                            style={{
                                                width: 50
                                            }}>{campaign.clicks}</div>
                                    </td>

                                    <td>
                                        <div
                                            style={{
                                                width: 80
                                            }}>{campaign.conversions}</div>
                                    </td>

                                    <td>
                                        <div
                                            style={{
                                                width: 120
                                            }}>{campaign.costPerClick}</div>
                                    </td>

                                    <td>
                                        <div
                                            style={{
                                                width: 130
                                            }}>{campaign.dateRange}</div>
                                    </td>

                                    <td>
                                        <div
                                            style={{
                                                width: 120
                                            }}>
                                            <button className='product-table-edit-btn'>Edit</button>
                                        </div>
                                    </td>

                                </tr>

                            ))
                        }
                    </tbody>
                </table>
            </div>


            <div className="pagination">
                <span>
                    Showing {indexOfFirstOrder + 1} - {Math.min(indexOfLastOrder, orders.length)} of {orders.length} Orders
                </span>
                <div className="pagination-controls">
                    <button className="prev-btn" onClick={prevPage} disabled={currentPage === 1}>
                        <BiChevronLeft size={40} color='black' />
                    </button>
                    <span>{currentPage} / {Math.ceil(orders.length / ordersPerPage)}</span>
                    <button className="next-btn" onClick={nextPage} disabled={currentPage === Math.ceil(orders.length / ordersPerPage)}>
                        <BiChevronRight size={40} color='black' />
                    </button>
                </div>
            </div>
        </div>
    )
}